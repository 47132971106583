.notFoundContainer {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    background: var(--dark-green);
    color: white;
}

.notFoundContainer h1 {
    font-size: 6rem;
    color: var(--gold);
    margin: 0;
    line-height: 1;
}

.notFoundContainer h2 {
    font-size: 2rem;
    color: var(--gold);
    margin: 1rem 0;
}

.notFoundContainer p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: rgba(255, 255, 255, 0.8);
}

.homeButton {
    background: var(--gold);
    color: var(--dark-green);
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.homeButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}

@media (max-width: 768px) {
    .notFoundContainer h1 {
        font-size: 4rem;
    }

    .notFoundContainer h2 {
        font-size: 1.5rem;
    }
} 