.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 1.5rem;
}

.header {
  background: linear-gradient(135deg, var(--dark-grey) 0%, var(--deep-green) 100%);  /* More contrast */
  padding: 2rem 3rem;  /* Increased padding */
  border-radius: 15px;
  margin-bottom: 2rem;
  border: 2px solid var(--gold);  /* Thicker, more prominent border */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);  /* Stronger shadow */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  backdrop-filter: blur(10px);  /* Subtle blur effect */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: var(--gold);
  font-size: 2.5rem;  /* Larger */
  margin: 0;
  font-weight: 800;  /* Bolder */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);  /* Text shadow for depth */
  letter-spacing: 1px;  /* Spaced out text */
}

.content {
  display: flex;
  gap: 2rem;
  min-height: calc(100vh - 200px);
}

.sidebar {
  width: 300px;
  background: linear-gradient(180deg, var(--medium-grey) 0%, var(--dark-grey) 100%);
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  border: 2px solid var(--gold);
  position: sticky;
  top: 1.5rem;
  height: fit-content;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  color: var(--text-light);
  text-decoration: none;
  border-radius: 12px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  font-weight: 500;
  border: 1px solid transparent;
}

.link:hover {
  background: var(--light-grey);
  color: var(--gold);
  transform: translateX(5px);
  border: 1px solid var(--gold-light);  /* Border on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);  /* Shadow on hover */
}

.activeLink {
  composes: link;
  background: var(--gold);
  color: var(--deep-green);
  font-weight: 700;
  transform: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);  /* Always show shadow */
}

.activeLink:hover {
  background: var(--gold-hover);
  color: var(--deep-green);
  transform: none;
}

.main {
  flex: 1;
  background: linear-gradient(180deg, var(--medium-grey) 0%, var(--dark-grey) 100%);  /* Gradient */
  padding: 2.5rem 3rem;  /* More padding */
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);  /* Stronger shadow */
  border: 2px solid var(--gold);  /* Thicker border */
  color: var(--text-light);
  min-width: 0;
  backdrop-filter: blur(10px);
}

.adminBadge {
  background-color: #FFD700;
  color: #004225;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.unauthorizedMessage {
  text-align: center;
  max-width: 600px;
  margin: 3rem auto;
  padding: 2.5rem;
  background-color: var(--medium-grey);
  border-radius: 12px;
  border: 1px solid var(--gold-light);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.subscribeButton {
  background-color: var(--gold);
  color: var(--deep-green);
  padding: 1rem 2rem;
  border-radius: 25px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1.5rem;
}

.subscribeButton:hover {
  background-color: var(--gold-light);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.adminControls {
  display: flex;
  gap: 10px;
}

.adminButton {
  background-color: #FFD700;
  color: #004225;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.adminButton:hover {
  background-color: #ffd900;
}

.downloadButton {
  background-color: #FFD700;
  color: #004225;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.downloadButton:hover {
  background-color: #ffd900;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 100%;
    padding: 0.75rem;
  }

  .header {
    padding: 1rem;
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 1.75rem;
    margin: 0;
  }

  .adminControls {
    display: flex;
    gap: 8px;
  }

  .adminButton {
    padding: 6px 12px;
    font-size: 12px;
    white-space: nowrap;
  }

  .content {
    flex-direction: column;
    gap: 1rem;
  }

  .sidebar {
    width: 100%;
    position: static;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-width: 1px;
    overflow-x: auto;  /* Allow horizontal scroll if needed */
    -webkit-overflow-scrolling: touch;
  }

  /* Make the navigation links row scrollable */
  .sidebar nav {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 4px; /* Space for scroll bar */
    scrollbar-width: none; /* Hide scrollbar Firefox */
    -ms-overflow-style: none; /* Hide scrollbar IE/Edge */
  }

  .sidebar nav::-webkit-scrollbar {
    display: none; /* Hide scrollbar Chrome/Safari */
  }

  .link {
    flex: 0 0 auto; /* Don't allow shrinking */
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
    margin: 0;
    white-space: nowrap;
  }

  .main {
    padding: 1rem;
    width: 100%;
  }

  .downloadButton {
    display: none; /* Hide download button on mobile */
  }

  .adminBadge {
    padding: 6px 12px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0.5rem;
  }

  .header {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .adminButton {
    padding: 4px 8px;
    font-size: 11px;
  }

  .sidebar {
    padding: 0.5rem;
  }

  .link {
    padding: 0.6rem 0.8rem;
    font-size: 0.85rem;
  }

  .main {
    padding: 0.75rem;
  }

  .adminBadge {
    padding: 4px 8px;
    font-size: 11px;
  }
}

/* Add smooth scrolling for iOS */
@supports (-webkit-touch-callout: none) {
  .sidebar nav {
    -webkit-overflow-scrolling: touch;
  }
}