.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: var(--deep-green);
}

.successCard {
  background: white;
  padding: 2.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.successCard h1 {
  color: var(--deep-green);
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.successCard p {
  color: #4a5568;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.features {
  margin: 2rem 0;
  text-align: left;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.features h2 {
  color: var(--deep-green);
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-align: center;
}

.features ul {
  list-style: none;
  padding: 0;
}

.features li {
  margin: 0.75rem 0;
  color: #4b5563;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
}

.primaryButton, .secondaryButton {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s;
  text-decoration: none;
  text-align: center;
  font-size: 1.1rem;
}

.primaryButton {
  background-color: var(--deep-green);
  color: white;
}

.primaryButton:hover {
  background-color: var(--medium-green);
  transform: translateY(-2px);
}

.secondaryButton {
  background-color: #e5e7eb;
  color: #374151;
}

.secondaryButton:hover {
  background-color: #d1d5db;
  transform: translateY(-2px);
}

.support {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
  color: #6b7280;
}

.support a {
  color: var(--deep-green);
  text-decoration: none;
}

.support a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .container {
    padding: 1rem;
  }
  
  .successCard {
    padding: 1.5rem;
  }
}