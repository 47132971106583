.container {
    max-width: 600px;
    margin: 4rem auto;
    padding: 2.5rem;
    background: var(--deep-green);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--gold);
}

.title {
    text-align: center;
    color: var(--gold);
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 600;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.label {
    color: var(--gold);
    font-size: 0.9rem;
    font-weight: 500;
}

.input {
    padding: 0.8rem 1rem;
    border: 1px solid rgba(255, 215, 0, 0.3);
    border-radius: 8px;
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    width: 100%;
}

.textarea {
    resize: vertical;
    min-height: 120px;
}

.input:focus {
    outline: none;
    border-color: var(--gold);
    background-color: rgba(255, 255, 255, 0.08);
}

.input:hover {
    border-color: var(--gold);
    background-color: rgba(255, 255, 255, 0.08);
}

.input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.button {
    background: var(--gold);
    color: var(--deep-green);
    padding: 0.8rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 0.5rem;
}

.button:hover:not(:disabled) {
    background: var(--gold-light);
    transform: translateY(-1px);
}

.button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.success, .error {
    text-align: center;
    margin-bottom: 1rem;
    padding: 0.8rem;
    border-radius: 8px;
    font-weight: 500;
}

.success {
    background-color: rgba(46, 204, 113, 0.1);
    border: 1px solid rgba(46, 204, 113, 0.3);
    color: #2ecc71;
}

.error {
    background-color: rgba(255, 68, 68, 0.1);
    border: 1px solid rgba(255, 68, 68, 0.3);
    color: #ff4444;
}

@media (max-width: 480px) {
    .container {
        margin: 2rem 1rem;
        padding: 1.5rem;
    }
}