.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.errorCard {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.errorCard h1 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}

.errorCard p {
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
}

.primaryButton, .secondaryButton {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s;
  text-decoration: none;
}

.primaryButton {
  background-color: #2563eb;
  color: white;
}

.primaryButton:hover {
  background-color: #1d4ed8;
}

.secondaryButton {
  background-color: #e5e7eb;
  color: #374151;
}

.secondaryButton:hover {
  background-color: #d1d5db;
}

.support {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.support a {
  color: #2563eb;
  text-decoration: none;
}

.support a:hover {
  text-decoration: underline;
} 