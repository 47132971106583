.contentWrapper {
  padding: 0;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.dateHeader {
  font-size: 1.8rem;
  color: var(--gold);
  margin: 1.5rem 0;
  text-align: center;
}

.tipsSection {
  width: 100%;
  background: transparent;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.tipRow {
  position: relative;
  display: grid;
  grid-template-columns: 45px 1fr auto auto;
  align-items: center;
  padding: 1rem 1.5rem;
  gap: 1.5rem;
  background: rgba(0, 40, 20, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  margin-top: 1.5rem;
  padding-bottom: 1.25rem;
  margin-bottom: 0.5rem;
}

/* Result overlays */
.tipRow.won {
  background: linear-gradient(
    rgba(0, 255, 0, 0.05),
    rgba(0, 255, 0, 0.05)
  ), rgba(0, 40, 20, 0.4);
}

.tipRow.placed {
  background: linear-gradient(
    rgba(255, 165, 0, 0.05),
    rgba(255, 165, 0, 0.05)
  ), rgba(0, 40, 20, 0.4);
}

.tipRow.lost {
  background: linear-gradient(
    rgba(255, 0, 0, 0.05),
    rgba(255, 0, 0, 0.05)
  ), rgba(0, 40, 20, 0.4);
}

.tipRow.nonRunner {
  background: linear-gradient(
    rgba(128, 128, 128, 0.05),
    rgba(128, 128, 128, 0.05)
  ), rgba(0, 40, 20, 0.4);
  opacity: 0.8;
}

.tipType {
  position: absolute;
  top: -8px;
  left: 12px;
  background: rgba(0, 0, 0, 0.4);
  color: var(--gold);
  font-size: 0.75rem;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 2px;
  text-transform: uppercase;
  border: 1px solid var(--gold);
}

.resultTag {
  position: absolute;
  bottom: -8px;
  right: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 2px;
  text-transform: uppercase;
}

/* Result states */
.tipRow .resultTag {
  background: #4a4a4a;
  color: white;
}

.tipRow.won {
  background: linear-gradient(rgba(0, 255, 0, 0.05), rgba(0, 255, 0, 0.05)), rgba(0, 40, 20, 0.4);
}

.tipRow.won .resultTag {
  background: #00c853;
}

.tipRow.placed {
  background: linear-gradient(rgba(255, 165, 0, 0.05), rgba(255, 165, 0, 0.05)), rgba(0, 40, 20, 0.4);
}

.tipRow.placed .resultTag {
  background: #ffa000;
}

.tipRow.lost {
  background: linear-gradient(rgba(255, 0, 0, 0.05), rgba(255, 0, 0, 0.05)), rgba(0, 40, 20, 0.4);
}

.tipRow.lost .resultTag {
  background: #d32f2f;
}

.tipRow.nonRunner {
  background: linear-gradient(rgba(128, 128, 128, 0.05), rgba(128, 128, 128, 0.05)), rgba(0, 40, 20, 0.4);
  opacity: 0.8;
}

.tipRow.nonRunner .resultTag {
  background: #616161;
}

.napRow {
  background: rgba(0, 40, 20, 0.8);
}

.napRow .tipType {
  background: #ff4d4d;
  color: white;
  border: none;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.silksImage {
  width: 45px;
  height: 45px;
}

.silksImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mainSection {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.horseName {
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
}

.raceInfo {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.odds {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--gold);
  margin-right: 0.5rem;
}

.result {
  font-size: 0.85rem;
  color: white;
  min-width: 70px;
  text-align: center;
}

.analysisButton {
  width: 36px;
  height: 36px;
  border: 1px solid var(--gold);
  border-radius: 6px;
  background: transparent;
  color: var(--gold);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.3s ease;
}

.analysisButton:hover {
  background: var(--gold);
  color: var(--dark-green);
}

.analysisPanel {
  position: relative;
  background: rgba(0, 40, 20, 0.8);
  padding: 1.5rem;
  margin-top: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  grid-column: 1 / -1;
  width: 100%;
}

.analysisContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.riderAndStats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: start;
}

.riderInfo {
  flex: 1;
}

.infoColumn h4 {
  color: var(--gold);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.infoColumn p {
  color: white;
  font-size: 0.9rem;
  line-height: 1.4;
}

.horseStats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.statsHeader {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.statsHeader span {
  color: var(--gold);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.statsValues {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.statsValues span {
  color: white;
  font-size: 0.9rem;
}

.analysisSection {
  margin-top: 0.5rem;
}

.analysisSection h4 {
  color: var(--gold);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
}

.analysisSection p {
  color: white;
  font-size: 0.9rem;
  line-height: 1.6;
}

.resultOverlay {
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.15;
  pointer-events: none;
  color: white;
}

/* Mobile styles */
@media (max-width: 768px) {
  .tipRow {
    display: grid;
    grid-template-columns: 35px minmax(0, 1fr) 45px 30px;
    padding: 0.75rem;
    gap: 0.75rem;
  }

  .mainSection {
    min-width: 0;
    overflow: hidden;
  }

  .horseName {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .odds {
    font-size: 0.9rem;
    white-space: nowrap;
    text-align: right;
  }

  .analysisButton {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }

  .analysisPanel {
    padding: 1rem;
    margin: 0.75rem 0 0;
    border-radius: 4px;
  }

  .riderAndStats {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .riderInfo {
    width: 100%;
  }

  .horseStats {
    width: 100%;
  }

  .statsHeader,
  .statsValues {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;
    padding-right: 0.5rem;
  }

  .statsHeader span,
  .statsValues span {
    font-size: 0.85rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .infoColumn h4 {
    font-size: 0.85rem;
    margin-bottom: 0.4rem;
  }

  .infoColumn p {
    font-size: 0.85rem;
    line-height: 1.4;
  }

  .analysisSection {
    margin-top: 1.25rem;
  }

  .resultOverlay {
    font-size: 1.2rem;
    right: 10%;
  }
}

/* Extra small screens */
@media (max-width: 360px) {
  .tipRow {
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .horseName {
    font-size: 0.85rem;
  }

  .analysisPanel {
    padding: 0.75rem;
  }

  .statsHeader,
  .statsValues {
    gap: 0.5rem;
  }

  .statsHeader span,
  .statsValues span {
    font-size: 0.8rem;
  }
}

/* Last tip in the section */
.tipRow:last-child {
  margin-bottom: 1rem;
}

.oddsFormatToggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark-green);
  border: 1px solid var(--gold);
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: var(--gold);
  transition: .4s;
  border-radius: 50%;
}

.toggleSwitch input:checked + .slider {
  background-color: var(--dark-green);
}

.toggleSwitch input:checked + .slider:before {
  transform: translateX(29px);
}

.toggleLabel {
  color: var(--gold);
  font-size: 0.8rem;
  user-select: none;
}

@media (max-width: 768px) {
  .oddsFormatToggle {
    position: static;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0.5rem 1rem;
    gap: 0.25rem;
  }

  .toggleSwitch {
    width: 40px;
    height: 20px;
  }

  .slider:before {
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 2px;
  }

  .toggleSwitch input:checked + .slider:before {
    transform: translateX(19px);
  }

  .toggleLabel {
    font-size: 0.7rem;
  }
}

.upgradeContainer {
    margin: 2rem 1rem;
    padding: 1.5rem;
    background: rgba(0, 40, 20, 0.8);
    border: 1px solid var(--gold);
    border-radius: 8px;
    text-align: center;
}

.upgradeContent {
    max-width: 400px;
    margin: 0 auto;
}

.upgradeContent h3 {
    color: var(--gold);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.upgradeContent p {
    color: white;
    margin-bottom: 1rem;
}

.upgradeContent ul {
    list-style: none;
    padding: 0;
    margin: 0 0 1.5rem;
    color: white;
    text-align: left;
    display: inline-block;
}

.upgradeContent li {
    margin: 0.5rem 0;
    font-size: 0.95rem;
}

.upgradeContent li::before {
    color: var(--gold);
    margin-right: 0.5rem;
}

.upgradeButton {
    background: var(--gold);
    color: var(--dark-green);
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.upgradeButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}

@media (max-width: 768px) {
    .upgradeContainer {
        margin: 1rem;
        padding: 1rem;
    }

    .upgradeContent h3 {
        font-size: 1.2rem;
    }

    .upgradeContent li {
        font-size: 0.9rem;
    }
}
