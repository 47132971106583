.analyticsContainer {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.header {
    text-align: center;
    margin-bottom: 2rem;
}

.header h2 {
    color: var(--gold);
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.statsContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* NAP Performance Styling */
.mainCard {
    background: var(--medium-grey);
    border-radius: 16px;
    padding: 2rem;
    border: 1px solid var(--gold-light);
}

.mainCard h3 {
    color: var(--gold);
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.streakContainer {
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.streakBox {
    text-align: center;
    padding: 1.5rem;
    background: var(--dark-grey);
    border-radius: 12px;
    min-width: 150px;
    border: 1px solid var(--gold-light);
}

.streakValue {
    font-size: 3rem;
    font-weight: bold;
    color: var(--gold);
    margin-bottom: 0.5rem;
}

.streakLabel {
    color: var(--text-light);
    font-size: 1.1rem;
}

/* Profit Cards Grid */
.profitGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.profitCard {
    background: var(--medium-grey);
    border-radius: 16px;
    padding: 1.5rem;
    text-align: center;
    border: 1px solid var(--gold-light);
}

.profitHeader {
    color: var(--gold);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.profitAmount {
    font-size: 2rem;
    font-weight: bold;
    margin: 0.5rem 0;
}

.profitDate {
    color: var(--text-light);
    font-size: 0.9rem;
}

/* Biggest Winner Card */
.winnerCard {
    background: var(--medium-grey);
    border-radius: 16px;
    padding: 2rem;
    text-align: center;
    border: 1px solid var(--gold-light);
}

.winnerCard h3 {
    color: var(--gold);
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.winnerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.winnerOdds {
    font-size: 3rem;
    font-weight: bold;
    color: var(--gold);
}

.winnerReturn {
    font-size: 1.5rem;
    color: #4cd137;
}

.winnerHorse {
    font-size: 1.1rem;
    color: var(--text-light);
}

.winnerType {
    font-size: 0.9rem;
    color: var(--gold-light);
    opacity: 0.8;
}

.winnerDate {
    font-size: 0.9rem;
    color: var(--text-light);
    opacity: 0.8;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .analyticsContainer {
        padding: 1rem;
    }

    .streakContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .profitGrid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .streakValue, .winnerOdds {
        font-size: 2.5rem;
    }

    .profitAmount {
        font-size: 1.8rem;
    }
}

/* Tablet Responsiveness */
@media (min-width: 769px) and (max-width: 1024px) {
    .profitGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.tipCount {
    font-size: 0.9rem;
    color: var(--text-light);
    opacity: 0.8;
    margin-top: 0.5rem;
}

.profit {
    color: #4cd137;
}

.loss {
    color: #e84118;
}

.betTypes {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    margin: 1rem 0;
}

.betType {
    text-align: center;
}

.betTypeLabel {
    font-size: 0.9rem;
    color: var(--gold);
    margin-bottom: 0.5rem;
}

.profitAmount {
    font-size: 1.5rem;
    font-weight: bold;
}