.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
    color: var(--text-light);
}

.container h1 {
    color: var(--gold);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
}

.section {
    background-color: var(--medium-grey);
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 30px;
    border: 1px solid var(--gold-light);
}

.section h2 {
    color: var(--gold);
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.section p {
    line-height: 1.6;
    margin-bottom: 1rem;
}

.section ul {
    list-style-type: none;
    padding-left: 0;
}

.section ul li {
    margin-bottom: 0.8rem;
    padding-left: 1.5rem;
    position: relative;
}

.section ul li:before {
    content: "•";
    color: var(--gold);
    position: absolute;
    left: 0;
}

.section a {
    color: var(--gold);
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
    border-bottom: 1px solid transparent;
}

.section a:hover {
    color: var(--gold-light);
    border-bottom: 1px solid var(--gold-light);
}

.section ul li a {
    display: inline-block;
    margin-right: 5px;
}

.helpResources {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.helpCard {
    background-color: var(--dark-grey);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid var(--gold-light);
}

.helpCard h3 {
    color: var(--gold);
    margin-bottom: 10px;
}

.helpCard p {
    margin-bottom: 15px;
}

.helpCard a {
    display: inline-block;
    padding: 8px 20px;
    background-color: var(--gold);
    color: var(--dark-grey);
    text-decoration: none;
    border-radius: 25px;
    font-weight: 600;
    transition: all 0.3s ease;
    border-bottom: none; /* Override the general link border */
}

.helpCard a:hover {
    background-color: var(--gold-light);
    transform: translateY(-2px);
    border-bottom: none; /* Override the general link border */
}

.helpline {
    color: var(--gold);
    font-weight: 600;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .container {
        padding: 20px 15px;
    }

    .container h1 {
        font-size: 2rem;
    }

    .section {
        padding: 20px;
    }

    .helpResources {
        grid-template-columns: 1fr;
    }
}