.membershipContainer {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  background-color: #002611;
  min-height: calc(100vh - 80px);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mainTitle {
  color: #ffd700;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.errorMessage {
  color: #ff4d4d;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: rgba(255, 77, 77, 0.1);
  border-radius: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  min-height: 60px;
}

.emailInput {
  padding: 0.75rem;
  margin: 0.5rem 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 80%;
  max-width: 300px;
  font-size: 1rem;
}

.tiersGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(280px, 400px));
  gap: 2rem;
  margin-top: 2rem;
  padding: 0 1rem;
  position: relative;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.tiersGrid.loading {
  pointer-events: none;
}

.tierCard {
  background: #001a0b;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  height: 100%;
  min-height: 500px;
}

.tierCard[data-tier="silver"] {
  border: 2px solid #C0C0C0;
}

.tierCard[data-tier="gold"] {
  border: 2px solid #ffd700;
  transform: scale(1.05);
  z-index: 1;
}

.popularBadge {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #ffd700;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transform: rotate(15deg);
}

.tierCard:hover:not(.disabled) {
  transform: translateY(-5px);
}

.tierCard[data-tier="gold"]:hover:not(.disabled) {
  transform: translateY(-5px) scale(1.05);
}

.tierCard.selected {
  border-color: #ffd700;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
}

.tierCard.disabled, 
.tiersGrid.loading .tierCard {
  opacity: 0.7;
  cursor: not-allowed;
  filter: grayscale(30%);
  pointer-events: none;
}

.tierName {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  color: #ffd700;
}

.price {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0.5rem 0 0.25rem 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price::after {
  content: 'per month';
  font-size: 1rem;
  font-weight: normal;
  opacity: 0.8;
  margin-top: 0.25rem;
}

.features {
  list-style: none;
  padding: 0 1rem;
  margin: 1.5rem 0;
  text-align: left;
  width: 100%;
  flex-grow: 1;
}

.features li {
  margin: 0.75rem 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.1rem;
}

.checkmark {
  color: #4CAF50;
  font-weight: bold;
}

.selectButton {
  background-color: #ffd700;
  color: #000;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.2s;
  margin-top: auto;
  width: 80%;
}

.selectButton:hover:not(:disabled) {
  background-color: #ffed4a;
  transform: scale(1.05);
}

.selectButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.selectedButton {
  background-color: #4CAF50;
  color: white;
}

.selectedButton:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .membershipContainer {
    padding: 1rem;
  }
  
  .tiersGrid {
    grid-template-columns: minmax(280px, 400px);
    gap: 1.5rem;
  }
  
  .mainTitle {
    font-size: 2rem;
  }
  
  .tierCard {
    min-height: 450px;
  }
  
  .tierCard[data-tier="gold"] {
    transform: none;
  }
  
  .tierCard[data-tier="gold"]:hover:not(.disabled) {
    transform: translateY(-5px);
  }
}

@media (max-width: 1024px) {
  .tiersGrid {
    gap: 1.5rem;
    padding: 0 0.5rem;
  }
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 38, 17, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.errorLink {
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
}

.errorLink:hover {
  opacity: 0.8;
}