.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main {
    flex: 1;
    padding: 20px;
    background-color: var(--deep-green);
  }