.welcomeContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: var(--dark-green);
}

.welcomeCard {
  background-color: var(--darker-green);
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcomeCard h1 {
  color: var(--gold);
  margin-bottom: 20px;
}

.checkmark {
  color: var(--gold);
  font-size: 48px;
  margin: 20px 0;
}

.successMessage {
  color: var(--gold);
  font-size: 18px;
  margin-bottom: 20px;
}

.instructions {
  color: white;
  margin-bottom: 30px;
  line-height: 1.5;
}

.homeButton {
  background-color: var(--gold);
  color: var(--dark-green);
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.homeButton:hover {
  background-color: #ffd700;
  transform: translateY(-2px);
} 