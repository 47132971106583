.navbar {
  background-color: var(--deep-green);  /* Updated */
  padding: 1rem 2rem;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid var(--gold);  /* Made more visible */
}

.navContainer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--gold);
  text-decoration: none;
  transition: color 0.3s;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.logo:hover {
  color: var(--gold-hover);
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navLink {
  text-decoration: none;
  color: var(--text-light);
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.navLink:hover {
  color: var(--gold);
  background-color: var(--light-grey);
}

.signupButton, .logoutButton {
  background-color: var(--gold);
  color: var(--dark-grey);
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 2px 8px rgba(255, 215, 0, 0.2);
}

.signupButton:hover {
  background-color: var(--gold-hover);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.3);
}

.logoutButton {
  background-color: transparent;
  border: 2px solid var(--gold);
  color: var(--gold);
}

.logoutButton:hover {
  background-color: var(--gold);
  color: var(--dark-grey);
}

.hamburger {
  display: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  width: 30px;
  height: 30px;
}

.hamburgerIcon {
  position: relative;
  display: block;
  background-color: var(--gold);
  width: 24px;
  height: 2px;
  transition: all 0.3s ease;
}

.hamburgerIcon::before,
.hamburgerIcon::after {
  content: '';
  position: absolute;
  background-color: var(--gold);
  width: 24px;
  height: 2px;
  transition: all 0.3s ease;
}

.hamburgerIcon::before {
  top: -8px;
}

.hamburgerIcon::after {
  bottom: -8px;
}

.hamburgerIcon.open {
  background-color: transparent;
}

.hamburgerIcon.open::before {
  transform: rotate(45deg);
  top: 0;
}

.hamburgerIcon.open::after {
  transform: rotate(-45deg);
  bottom: 0;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navLinks {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--medium-green);  /* Updated */
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);  /* Enhanced shadow */
    border-top: 1px solid var(--gold-light);    /* Added */
  }

  .navLinks.active {
    display: flex;
  }

  .navLink, .signupButton, .logoutButton {
    width: 100%;
    text-align: center;
    padding: 0.75rem;
    border-radius: 8px;
  }

  .navLink {
    background-color: var(--deep-green);  /* Updated */
    color: var(--text-light);
  }

  .navLink:hover {
    background-color: var(--light-green);  /* Updated */
    color: var(--gold);
  }

  .signupButton {
    margin-top: 0.5rem;
    background-color: var(--gold);
    color: var(--deep-green);  /* Updated */
  }

  .logoutButton {
    margin-top: 0.5rem;
    border-color: var(--gold);
    background-color: transparent;
  }

  .logoutButton:hover {
    background-color: var(--gold);
    color: var(--deep-green);  /* Updated */
  }
}

.logoImage {
  height: 55px;  /* Increased size */
  width: auto;
  display: block;
  transition: transform 0.3s ease;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));  /* Subtle shadow */
}

.logoImage:hover {
  transform: scale(1.05);  /* Slight grow effect on hover */
}

/* For mobile screens */
@media (max-width: 768px) {
  .logoImage {
      height: 45px;  /* Slightly smaller on mobile */
  }
}