:root {
  --deep-green: #0a2f25;
  --medium-green: #144a3d;
  --gold: #ffd700;
  --gold-hover: #f8c400;
}

.landingPage {
  background-color: var(--deep-green);
  color: white;
}

/* Hero Section */
.hero {
  background: linear-gradient(135deg, var(--deep-green) 0%, var(--medium-green) 100%);
  padding: 6rem 2rem;
  text-align: center;
}

.heroContent {
  max-width: 1200px;
  margin: 0 auto;
}

.hero h1 {
  font-size: clamp(2.5rem, 6vw, 4rem);
  margin-bottom: 1.5rem;
  background: linear-gradient(to right, var(--gold), #fff6a3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero p {
  font-size: clamp(1.1rem, 3vw, 1.5rem);
  margin-bottom: 2.5rem;
  opacity: 0.9;
}

/* Buttons */
.heroButtons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.primaryButton {
  background: var(--gold);
  color: var(--deep-green);
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.primaryButton:hover {
  background: var(--gold-hover);
  transform: translateY(-2px);
}

.secondaryButton {
  background: transparent;
  color: var(--gold);
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid var(--gold);
  transition: all 0.3s ease;
}

.secondaryButton:hover {
  background: rgba(255, 215, 0, 0.1);
  transform: translateY(-2px);
}

/* Stats Overview */
.statsOverview {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: -3rem auto 0;
  padding: 0 2rem;
  position: relative;
}

.statCard {
  background: rgba(0, 0, 0, 0.3);
  padding: 2.5rem 4rem;
  border-radius: 20px;
  text-align: center;
  border: 1px solid rgba(255, 215, 0, 0.2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
}

.statCard:hover {
  transform: translateY(-5px);
}

.statCard h3 {
  color: var(--gold);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.statValue {
  font-size: 3.5rem;
  font-weight: bold;
  color: var(--gold);
  margin: 1rem 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Chart Section */
.chartSection {
  padding: 4rem 1rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
}

.chartContainer {
  margin-bottom: 4rem;
}

.chartSection h2 {
  color: var(--gold);
  margin-bottom: 2rem;
  font-size: 2rem;
}

/* Membership Tiers */
.membershipTiers {
  padding: 4rem 2rem;
  background: var(--medium-green);
  text-align: center;
}

.membershipTiers h2 {
  color: var(--gold);
  margin-bottom: 3rem;
  font-size: 2rem;
}

.tierCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.tierCard {
  background: rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid rgba(255, 215, 0, 0.1);
  transition: all 0.3s ease;
}

.tierCard.featured {
  transform: scale(1.05);
  border-color: var(--gold);
}

.price {
  font-size: 2.5rem;
  color: var(--gold);
  margin: 1rem 0;
}

.tierCard ul {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.tierCard li {
  margin: 0.8rem 0;
  opacity: 0.9;
}

/* Features */
.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.feature {
  text-align: center;
}

.icon {
  color: var(--gold);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Final CTA */
.finalCTA {
  text-align: center;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, var(--deep-green) 0%, var(--medium-green) 100%);
}

.finalCTA h2 {
  color: var(--gold);
  margin-bottom: 1rem;
  font-size: 2rem;
}

.finalCTA p {
  margin-bottom: 2rem;
  opacity: 0.9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .statsOverview {
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .statCard {
    padding: 2rem;
    width: 90%;
  }

  .statValue {
    font-size: 2.8rem;
  }

  .features {
    grid-template-columns: 1fr;
  }

  .tierCard.featured {
    transform: none;
  }

  .primaryButton,
  .secondaryButton {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .finalCTA {
    padding: 3rem 1rem;
  }

  .chartSection {
    padding: 2rem 0.5rem;
  }

  .chartSection h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .chartSection .secondaryButton {
    margin-top: 2rem;
  }

  .chartContainer {
    margin-bottom: 5rem;
  }
}