.container {
    max-width: 400px;
    margin: 4rem auto;
    padding: 2.5rem;
    background-color: var(--medium-green);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border: 1px solid var(--gold-light);
  }
  
  .title {
    text-align: center;
    color: var(--gold);
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 600;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .label {
    font-weight: 500;
    color: var(--text-light);
    font-size: 0.95rem;
  }
  
  .input {
    padding: 0.75rem;
    border: 2px solid var(--light-green);
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background-color: var(--deep-green);
    color: var(--text-light);
  }
  
  .input:focus {
    outline: none;
    border-color: var(--gold);
    box-shadow: 0 0 0 3px var(--gold-light);
    background-color: var(--light-green);
  }
  
  .input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .button {
    background-color: var(--gold);
    color: var(--dark-grey);
    padding: 0.85rem;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
  }
  
  .button:hover {
    background-color: var(--gold-hover);
    transform: translateY(-2px);
  }
  
  .button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
  
  .success {
    text-align: center;
    margin-top: 1rem;
    color: var(--success);
    padding: 0.75rem;
    background-color: rgba(40, 167, 69, 0.1);
    border-radius: 8px;
    border: 1px solid var(--success);
  }
  
  .error {
    color: var(--error);
    text-align: center;
    margin-bottom: 1rem;
    padding: 0.75rem;
    background-color: rgba(255, 68, 68, 0.1);
    border-radius: 8px;
    border: 1px solid var(--error);
  }
  
  @media (max-width: 480px) {
    .container {
      margin: 2rem auto;
      padding: 1.5rem;
    }
  
    .title {
      font-size: 1.75rem;
    }
  }