.footer {
  background-color: var(--deep-green);
  color: var(--text-light);
  padding: 3rem 0 1rem;
  margin-top: auto;
  border-top: 2px solid var(--gold);
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1); /* Optional: adds subtle shadow */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.section {
  margin-bottom: 2rem;
}

.title {
  color: var(--gold);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.description {
  color: var(--text-light);
  line-height: 1.6;
  margin-bottom: 1rem;
}

.links {
  list-style: none;
  padding: 0;
}

.links li {
  margin-bottom: 0.5rem;
}

.links a {
  color: var(--text-light);
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  padding-bottom: 2px;
}

.links a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--gold);
  transition: width 0.3s ease;
}

.links a:hover {
  color: var(--gold);
}

.links a:hover:after {
  width: 100%;
}

.bottom {
  text-align: center;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9rem;
  color: var(--text-light);
}

.gambleAware {
  margin-top: 1rem;
  color: var(--text-muted);
}

.gambleAware a {
  color: var(--gold);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
}

.gambleAware a:hover {
  color: var(--gold-light);
  border-bottom: 1px solid var(--gold-light);
}

.social {
  margin-top: 1rem;
}

.socialLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-light);
  text-decoration: none;
  transition: color 0.2s ease;
}

.socialLink:hover {
  color: var(--gold);
}

.socialLink i {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .footer {
    padding: 1.5rem 0 0.5rem;
  }

  .container {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 1rem;
    padding: 0 1rem;
  }

  .section {
    margin-bottom: 1rem;
  }

  .title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .description {
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .links li {
    margin-bottom: 0.3rem;
  }

  .links a {
    font-size: 0.9rem;
  }

  .bottom {
    padding-top: 1rem;
    margin-top: 1rem;
    font-size: 0.8rem;
  }

  .gambleAware {
    margin-top: 0.8rem;
    font-size: 0.8rem;
    padding: 0 1rem;
  }
}