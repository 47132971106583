.legalContainer {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: var(--medium-green);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border: 1px solid var(--gold-light);
    color: var(--text-light);
  }
  
  .title {
    color: var(--gold);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
  
  .section {
    margin-bottom: 2rem;
  }
  
  .section h2 {
    color: var(--gold);
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .section p {
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .section ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  
  .section li {
    margin-bottom: 0.5rem;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .legalContainer {
      margin: 1rem;
      padding: 1.5rem;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .section h2 {
      font-size: 1.3rem;
    }
  }