.chartContainer {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  margin: 2rem 0;
  border: 1px solid rgba(255, 215, 0, 0.3);
}

.chartContainer h2 {
  color: #ffd700;
  margin-bottom: 1.5rem;
  text-align: center;
}

.chartWrapper {
  height: 400px;
  width: 100%;
}

@media (max-width: 768px) {
  .chartWrapper {
    height: 300px;
  }
} 