.resultsContainer {
    padding: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
    color: var(--text-light);
  }
  
  .header {
    background: linear-gradient(135deg, var(--dark-grey) 0%, var(--deep-green) 100%);
    padding: 1.5rem 2rem;
    border-radius: 15px;
    margin-bottom: 2rem;
    border: 2px solid var(--gold);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  }
  
  .header h2 {
    color: var(--gold);
    font-size: 2rem;
    margin: 0;
    font-weight: 800;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .filtersSection {
    background: rgba(0, 40, 20, 0.5);
    padding: 1.5rem;
    border-radius: 12px;
    margin-bottom: 2rem;
    border: 1px solid var(--gold-light);
  }
  
  .filterGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .filterInput {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid var(--gold-light);
    padding: 0.75rem 1rem;
    border-radius: 8px;
    color: var(--text-light);
    width: 100%;
    padding-right: 2rem;
  }
  
  .filterInput::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .clearInputBtn {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--gold);
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: opacity 0.2s ease;
  }
  
  .clearInputBtn:hover {
    opacity: 1;
  }
  
  /* Optional: add a subtle circle background on hover */
  .clearInputBtn:hover::before {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background: rgba(255, 215, 0, 0.1);
    border-radius: 50%;
    z-index: -1;
  }
  
  .profitLoss {
    background: linear-gradient(135deg, rgba(0, 40, 20, 0.8) 0%, rgba(0, 60, 30, 0.8) 100%);
    padding: 1.5rem;
    border-radius: 12px;
    margin-bottom: 2rem;
    border: 1px solid var(--gold);
    text-align: center;
  }
  
  .profitLoss h3 {
    color: var(--text-light);
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }
  
  .profit {
    color: #00ff00;
    font-size: 1.8rem;
    font-weight: 700;
  }
  
  .loss {
    color: #ff4444;
    font-size: 1.8rem;
    font-weight: 700;
  }
  
  .resultsTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 12px;
    overflow: hidden;
    background: rgba(0, 40, 20, 0.5);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .resultsTable th {
    background: rgba(0, 0, 0, 0.3);
    color: var(--gold);
    font-weight: 600;
    padding: 1rem;
    text-align: left;
  }
  
  .resultsTable td {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 215, 0, 0.1);
  }
  
  .winner {
    background: rgba(0, 180, 0, 0.25);
  }
  
  .winner:hover {
    background: rgba(0, 180, 0, 0.35);
  }
  
  .loser {
    background: rgba(180, 0, 0, 0.25);
  }
  
  .loser:hover {
    background: rgba(180, 0, 0, 0.35);
  }
  
  .placed {
    background: rgba(255, 140, 0, 0.25);
  }
  
  .placed:hover {
    background: rgba(255, 140, 0, 0.35);
  }
  
  .nr {
    background: rgba(100, 100, 100, 0.25);
  }
  
  .nr:hover {
    background: rgba(100, 100, 100, 0.35);
  }

  .filterWrapper {
    position: relative;
    width: 100%;
  }
  
  .suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--deep-green);
    border: 1px solid var(--gold);
    border-top: none;
    border-radius: 0 0 8px 8px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .suggestionItem {
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    color: var(--text-light);
    border-bottom: 1px solid rgba(255, 215, 0, 0.1);
  }
  
  .suggestionItem:last-child {
    border-bottom: none;
  }
  
  .suggestionItem:hover {
    background: rgba(255, 215, 0, 0.1);
    color: var(--gold);
  }
  
  /* Highlight matching text */
  .suggestionMatch {
    color: var(--gold);
    font-weight: 600;
  }
  
  /* Scrollbar styling for suggestions */
  .suggestions::-webkit-scrollbar {
    width: 8px;
  }
  
  .suggestions::-webkit-scrollbar-track {
    background: var(--dark-grey);
    border-radius: 0 0 8px 0;
  }
  
  .suggestions::-webkit-scrollbar-thumb {
    background: var(--gold-light);
    border-radius: 4px;
  }

  .filterActions {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    padding: 0.5rem;
    /* Remove any opacity transitions */
  }
  
  .clearFiltersBtn {
    background: var(--gold);
    color: var(--deep-green);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
  }
  
  .clearFiltersBtn:hover {
    transform: translateY(-2px);
    background: #ffb347;
  }
  
  .clearFiltersBtn:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
    padding: 0.75rem;
    background-color: var(--medium-green);
    border-radius: 8px;
  }
  
  .pageButton {
    padding: 0.5rem 1rem;
    border: 1px solid var(--gold);
    border-radius: 4px;
    background-color: var(--deep-green);
    color: var(--gold);
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 500;
  }
  
  .pageButton:hover:not(:disabled) {
    background-color: var(--gold);
    color: var(--deep-green);
  }
  
  .pageButton:disabled {
    background-color: var(--medium-green);
    border-color: var(--text-muted);
    color: var(--text-muted);
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .pageInfo {
    font-size: 0.9rem;
    color: var(--text-light);
    white-space: nowrap;
  }
  
  @media (max-width: 768px) {
    .resultsContainer {
      padding: 1rem;
    }
  
    .header {
      padding: 1rem;
    }
  
    .header h2 {
      font-size: 1.5rem;
    }
  
    .filterGroup {
      grid-template-columns: 1fr;
    }
  
    .resultsTable {
      display: block;
      overflow-x: auto;
    }
  
    .resultsTable th,
    .resultsTable td {
      padding: 0.75rem;
      font-size: 0.9rem;
    }

    .suggestions {
        max-height: 150px;
    }

    .suggestionItem {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    }

    .filterActions {
    margin-top: 0.75rem;
    }

    .clearFiltersBtn {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    }

    .pagination {
      flex-direction: column;
      gap: 0.75rem;
    }

    .pageInfo {
      order: -1;
    }
  }