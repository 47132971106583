.setupContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: var(--dark-green);
}

.setupCard {
  background-color: var(--darker-green);
  padding: 40px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.setupCard h1 {
  color: var(--gold);
  margin-bottom: 30px;
  text-align: center;
}

.inputGroup {
  margin-bottom: 20px;
}

.inputGroup label {
  display: block;
  color: white;
  margin-bottom: 8px;
}

.inputGroup input {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 16px;
}

.inputGroup input:focus {
  outline: none;
  border-color: var(--gold);
}

.error {
  color: #ff6b6b;
  margin-bottom: 20px;
  text-align: center;
}

.submitButton {
  width: 100%;
  background-color: var(--gold);
  color: var(--dark-green);
  border: none;
  padding: 12px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitButton:hover {
  background-color: #ffd700;
  transform: translateY(-2px);
} 