:root {
  --deep-green: #0a2f25;
  --medium-green: #144a3d;
  --gold: #ffd700;
  --gold-hover: #f8c400;
  --error: #ff4444;
  --success: #28a745;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: white;
}

/* Header */
.container h1 {
  color: var(--gold);
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

/* Error Message */
.error {
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid var(--error);
  color: var(--error);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Trial Promo Section */
.trialPromo {
  background: rgba(255, 215, 0, 0.05);
  border: 1px solid var(--gold);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
}

.trialPromo h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.trialPromo ul {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.trialPromo li {
  margin: 0.8rem 0;
  font-size: 1.1rem;
}

.trialNote {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  margin-top: 1rem;
}

/* Subscription Section */
.subscriptionSection {
  background: var(--medium-green);
  border-radius: 15px;
  padding: 2rem;
  border: 1px solid rgba(255, 215, 0, 0.2);
}

.subscriptionSection h2 {
  color: var(--gold);
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

/* Active Subscription */
.activeSubscription {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
}

.tierInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 215, 0, 0.2);
}

.tierInfo h3 {
  font-size: 1.5rem;
  color: var(--gold);
  margin: 0;
}

.price {
  font-size: 1.3rem;
  color: white;
}

.statusLabel, .renewalDate {
  color: rgba(255, 255, 255, 0.9);
  margin: 0.5rem 0;
}

/* Buttons */
.buttonGroup {
  margin-top: 1.5rem;
  text-align: center;
}

.manageButton {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.button {
  background: var(--gold);
  color: var(--deep-green);
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
}

.button:hover {
  background: var(--gold-hover);
  transform: translateY(-2px);
}

.button.cancelButton {
  background: transparent;
  border: 2px solid var(--error);
  color: var(--error);
}

.button.cancelButton:hover {
  background: rgba(255, 68, 68, 0.1);
}

/* Cancel confirmation styles */
.cancelConfirm {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid rgba(255, 68, 68, 0.3);
  border-radius: 10px;
  background: rgba(255, 68, 68, 0.1);
}

.cancelButtons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.confirmCancelButton {
  background: var(--error);
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.keepButton {
  background: var(--success);
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

/* Inactive Subscription */
.inactiveSubscription {
  text-align: center;
  padding: 2rem;
}

.inactiveMessage {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
}

/* Loading State */
.loading {
  text-align: center;
  padding: 3rem;
  color: var(--gold);
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .tierOptions {
    flex-direction: column;
    gap: 10px;
  }

  .tierOption {
    width: 100%;
    text-align: center;
  }

  .buttonGroup {
    flex-direction: column;
    align-items: center;
  }

  .resubscribeButton {
    width: 100%;
    margin-top: 10px;
  }
}

/* Add to your existing styles */
.manageButton {
    display: inline-block;
    background-color: var(--gold);
    color: var(--deep-green);
    padding: 12px 24px;
    border-radius: 5px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    width: auto;
    min-width: 200px;
}

.manageButton:hover {
    background-color: var(--gold-hover);
    transform: translateY(-2px);
}

.manageButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
}

/* Add these new styles to your existing CSS */

.statusBanner {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 1.5rem;
    margin: 1.5rem 0;
}

.statusInfo {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.statusLabel {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
}

.activeStatus {
    color: var(--success);
    font-weight: bold;
    font-size: 1rem;
}

.cancellingStatus {
    color: var(--gold);
    font-weight: bold;
    font-size: 1rem;
}

.cancellationNote {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.95rem;
    margin-top: 0.5rem;
    line-height: 1.4;
}

.renewalNote {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.95rem;
    margin-top: 0.5rem;
}

.cancelledStatus {
    color: #dc3545;
    font-weight: bold;
}

.tierSelection {
    margin: 20px 0;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
}

.tierOptions {
    display: flex;
    gap: 20px;
    margin-top: 15px;
}

.tierOption {
    flex: 1;
    padding: 15px;
    border: 2px solid rgba(255, 215, 0, 0.3);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
}

.tierOption:hover {
    border-color: #FFD700;
    background: rgba(255, 215, 0, 0.1);
}

.tierOption.selected {
    border-color: #FFD700;
    background: rgba(255, 215, 0, 0.2);
}

.tierOption h4 {
    margin: 0;
    color: #FFD700;
}

.tierOption .price {
    margin: 10px 0 0;
    font-size: 1.2em;
    color: #ffffff;
}

.resubscribeButton {
    background-color: var(--gold);
    color: var(--deep-green);
    font-size: 1.1rem;
    padding: 1rem 2rem;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.resubscribeButton:hover {
    background-color: var(--gold-hover);
    transform: translateY(-2px);
}

.resubscribeButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
}

.userInfo {
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
}

.detailsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin: 1rem 0;
}

.detailItem {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.label {
    font-size: 0.9rem;
    color: #888;
}

.value {
    font-size: 1.1rem;
    font-weight: 500;
}

.tierBenefits {
    background: rgba(255, 255, 255, 0.05);
    padding: 1.5rem;
    border-radius: 8px;
    margin: 1.5rem 0;
}

.benefitsList {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
}

.benefitsList li {
    padding: 0.5rem 0;
    color: #c0c0c0;
}

.benefitsList li:before {
    content: "✓";
    color: #4CAF50;
    margin-right: 0.5rem;
}

.freeAccessBanner {
    background-color: rgba(255, 215, 0, 0.1);
    border: 1px solid #ffd700;
}

.freeAccessStatus {
    color: #ffd700;
    font-weight: 600;
}

.freeAccessNote {
    color: #ffd700;
    margin-top: 8px;
}