.statsContainer {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #002611;
  min-height: calc(100vh - 80px);
  color: white;
}

.pointsExplanation {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 10px;
  margin: 2rem 0;
}

.pointsExplanation h2 {
  color: #ffd700;
  margin-bottom: 1rem;
}

.pointsExplanation ul {
  list-style-type: none;
  padding-left: 1rem;
}

.pointsExplanation li {
  margin: 0.5rem 0;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin: 2rem 0;
}

.statCard {
  background: rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
  border: 1px solid rgba(255, 215, 0, 0.3);
  transition: transform 0.2s;
}

.statCard:hover {
  transform: translateY(-5px);
}

.statCard h3 {
  color: #ffd700;
  margin-bottom: 1rem;
}

.profitValue {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.positive {
  color: #4CAF50;
}

.negative {
  color: #f44336;
}

.monthlyHistory {
  margin-top: 3rem;
}

.monthlyHistory h2 {
  color: #ffd700;
  margin-bottom: 1.5rem;
}

.monthlyGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
}

.monthCard {
  background: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgba(255, 215, 0, 0.3);
}

.monthCard h3 {
  color: #ffd700;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .statsContainer {
    padding: 1rem;
  }
  
  .statsGrid {
    grid-template-columns: 1fr;
  }
  
  .profitValue {
    font-size: 1.5rem;
  }
} 