:root {
  /* Base colors */
  --deep-green: #0a2f25;    /* Darkest green for main background */
  --medium-green: #144a3d;  /* Medium green for containers */
  --light-green: #1b5d4e;   /* Light green for hover states */
  
  /* Theme mapping */
  --bg-primary: var(--deep-green);
  --bg-secondary: var(--medium-green);
  --bg-tertiary: var(--light-green);
  
  /* Gold accent colors */
  --gold: #ffd700;
  --gold-hover: #f8c400;
  --gold-light: rgba(255, 215, 0, 0.1);
  --gold-dark: #cca700;
  
  /* Text colors */
  --text-light: #ffffff;
  --text-muted: rgba(255, 255, 255, 0.8);
  
  /* Status colors */
  --error: #ff4d4d;
  --success: #00cc66;
  --success-dark: #00a352;
  --warning: #ffb84d;
  --info: #4d94ff;
  --placed: #3399ff;

  /* Gradients */
  --gradient-primary: linear-gradient(135deg, var(--deep-green) 0%, var(--medium-green) 100%);
  --gradient-gold: linear-gradient(to right, var(--gold), #fff6a3);
  --gradient-overlay: linear-gradient(45deg, transparent 0%, rgba(255, 215, 0, 0.03) 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  background-color: var(--bg-primary);
  color: var(--text-light);
  line-height: 1.6;
}

/* Add some global button styles */
button, .button {
  font-family: inherit;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Common button styles */
.primary-button {
  background: var(--gold);
  color: var(--deep-green);
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 215, 0, 0.3);
  border: none;
}

.primary-button:hover {
  background: var(--gold-hover);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 215, 0, 0.4);
}

/* Card styles */
.card {
  background: var(--bg-secondary);
  border-radius: 15px;
  padding: 2rem;
  border: 1px solid var(--gold-light);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Section styles */
.section {
  padding: 5rem 2rem;
  position: relative;
}

.section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--gradient-overlay);
}

/* Gradient text */
.gradient-text {
  background: var(--gradient-gold);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Responsive breakpoints */
@media (max-width: 768px) {
  .section {
    padding: 3rem 1rem;
  }
}